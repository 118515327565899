<template>
  <div class="allDetails">
    <div class="titleLabel">
      <p @click="home">首页 <i class="el-icon-arrow-right"></i></p>
      <p @click="Recruiting">招标公告 <i class="el-icon-arrow-right"></i></p>
      <span>{{ Msg.subject }}</span>
    </div>
    <div v-if="show3" class="titleWord">
      <div class="WordText">
        <div class="rightWord">
          <p>【{{ Msg.createDate }}】【{{ Msg.subject }}】</p>
          <!--中部内容-->
          <div class="situation">
            <div class="right-tender">
              <div class="right-item">
                <span class="right-item-label">招标单位: </span><span>{{Msg.company}} </span>
              </div>
              <div class="right-item">
                <span class="right-item-label">项目名称: </span><span>{{Msg.projectName}} </span>
              </div>

              <div class="right-item">
                <span class="right-item-label">项目地址: </span><span>{{Msg.projectAddress}} </span>
              </div>
              <div class="right-item">
                <span class="right-item-label">招标编号: </span><span>{{Msg.tenderInfoNo}} </span>
              </div>
<!--              <div class="right-item">-->
<!--                <span class="right-item-label">招标编号: </span><span>{{Msg.subject}} </span>-->
<!--              </div>-->
              <div class="right-item">
                <span class="right-item-label">招标名称: </span><span>{{Msg.name}} </span>
              </div>
<!--              <div class="right-item">-->
<!--                <span class="right-item-label">创建时间: </span><span>{{Msg.createDate}} </span>-->
<!--              </div>-->
              <div class="right-item">
                <span class="right-item-label">报名截止时间: </span><span>{{Msg.signUpEndDate}} </span>
              </div>

              <div class="right-item">
                <span class="right-item-label">状态: </span>
                <span>{{Msg.status === 'pending' ? '进行中' : '已结束'}} </span>
              </div>
              <div class="right-item">
                <span class="right-item-label">附件: </span>
                <div class="file-item" v-if="Msg.atts.length !== 0 && applyShow === true">
                  <div v-for="(item, index) in Msg.atts" :key="index">
                    <a class="a-style" :href="'https://www.zjhy.cc/api' +item.fileUrl">{{item.fileName}}</a>
                  </div>
                </div>
                <div class="file-item" v-else>
                  <div v-for="(item, index) in Msg.atts" :key="index" @click="attsDownload">
                    <a class="a-style" href="#">{{item.fileName}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="leftWord">
          <div :class="textType==='报名'||textType==='已报名'? 'SignBut':'TimeBut'" @click="ISign">{{textType}}</div>
          <div class="step">
            <div v-for="(item, index) in Msg.briefs" :key="index" class="stepMessage">
              <span>
                <div class="stepRound"></div>
              <div class="xianS"></div>
              </span>
              <div class="MessageStep">
                <h1>{{ item.title }}</h1>
                <P v-if="item.cells.开始 != ''">开始：{{ item.cells.开始 }}</P>
                <P v-if="item.cells.结束 != ''">结束：{{ item.cells.结束 }}</P>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <signDs v-if="show4"></signDs>
  </div>
</template>

<script>
import axios from 'axios'
import { RecruitingAt, getPrice, apply } from '@/api/api'
import signDs from '@/components/details/SignDs'
// import pdf from 'vue-pdf'

export default {
  name: 'tender_details',
  props: {},
  components: {
    signDs
    // pdf
  },
  data () {
    return {
      show3: true,
      show4: false,
      Msg: [],
      numPages: 1,
      Time: [{ name: '报名时间' }, { name: '文件下载时间' }, { name: '招标文件售卖时间' }, { name: '投标时间' }, { name: '开标时间' }],
      applyShow: false,
      timeBool: '', // 根据时间判断招标是否过期
      supplierBool: '', // 判断是否是受邀用户
      id: '',
      textType: ''
    }
  },
  created () {
    this.id = this.$route.query.id
    this.Message()
    this.butStatus()
  },
  methods: {
    home () {
      this.$router.replace('/')
    },
    Recruiting () {
      location.reload()
    },
    dateFormat: function (time) {
      var date = new Date(time)
      var year = date.getFullYear()
      /* 在日期格式中，月份是从0开始的，因此要加0
         * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
         * */
      var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
      var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      var hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
      var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
      // var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
      // 拼接 + ':' + seconds
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes
    },
    async Message () {
      axios.defaults.headers.token = localStorage.getItem('token')
      await RecruitingAt({
        method: 'tenderInfo',
        id: localStorage.getItem('Rid')
        // fileId: ''
      }, res => {
        if (res.data.data === null) {
          this.$message.error('未能查询到数据！')
        } else {
          this.Msg = res.data.data
          const endTime = res.data.data.signUpEndDate
          const time = new Date()
          const timeT = this.dateFormat(time)
          if (endTime < timeT) {
            this.timeBool = 'true'
            this.$message.error('报名时间已截止')
          } else {
            this.timeBool = 'false'
          }
          // 查询是否报名
          getPrice({
            method: 'reportPrice',
            tenderInfoId: this.Msg.id
          }, resS => {
            if (resS.data.success === false) {
              this.$message.error(resS.data.message)
              return
            }
            // noSign 未报名   signUp 已报名
            const resTwoData = resS.data.data
            if (resTwoData === 'noSign') {
              this.applyShow = false
            } else if (resTwoData.signState === 'signUp') {
              this.applyShow = true
            } else if (resTwoData === null) {
              localStorage.clear()
              this.$router.replace('/login/login')
            }
            // noSign 未报名   signUp 已报名
            // const resTwoData = resS.data.data
            this.butStatus()
          })
        }
      })
    },
    butStatus () {
      if (this.applyShow === true && this.timeBool === 'false') {
        this.textType = '已报名'
      } else if (this.applyShow === false && this.timeBool === 'false') {
        this.textType = '报名'
      } else {
        this.textType = '报名截止'
      }
    },
    ISign () {
      if (localStorage.getItem('EUJSESSIONID') === '' || localStorage.getItem('EUJSESSIONID') === null) {
        this.$router.push('/login/login')
      } else if (this.timeBool === 'true') {
        this.$message.error('报名已截止！')
      } else if (this.applyShow === true) {
        this.$message.error('请勿重复报名！')
      } else if (this.id === undefined || this.id === '') {
        apply({
          method: 'updatePrice',
          data: [{
            tenderInfoId: localStorage.getItem('Rid')
          }]
        }, res => {
          if (res.data.success === true || this.timeBool === 'false') {
            this.$message.success(res.data.message)
            this.applyShow = true
            this.Message()
            this.butStatus()
          } else {
            this.$message.error(res.data.message)
          }
        })
      } else if (this.id !== undefined || this.id !== '') {
        apply({
          method: 'getIsInviteSupplier',
          unitId: localStorage.getItem('userSupplier'),
          tenderInfoNo: this.Msg.tenderInfoNo
        }, res => {
          this.supplierBool = res.data.data
          if (res.data.success !== true) {
            this.$message.error(this.data.message)
          } else if (this.supplierBool !== true) {
            this.$message.error('抱歉您不是受邀供应商！')
          } else {
            apply({
              method: 'updatePrice',
              data: [{
                tenderInfoId: localStorage.getItem('Rid')
              }]
            }, res => {
              if (res.data.success === true || this.timeBool === 'false') {
                this.$message.success(res.data.message)
                this.applyShow = true
                this.Message()
                this.butStatus()
              } else {
                this.$message.error(res.data.message)
              }
            })
          }
        })
      }
    },
    attsDownload () {
      if (this.Msg.atts.length === 0) {
        this.$message.error('暂无附件信息')
      } else {
        this.$message.error('需要先报名才能下载附件')
      }
    }
  }
}
</script>

<style scoped>
.allDetails {
  width: 100%;
  height: 100%;
}

.titleLabel {
  height: 40px;
  display: flex;
  line-height: 25px;
  cursor: pointer;
  border-bottom: 1px solid #e3e3e3;
  font-size: 14px;
}

.titleLabel > p {
  margin-left: 20px;
  color: #333333FF;
  font-weight: bold;
}

.titleLabel > span {
  margin-left: 20px;
  color: #333333;
  font-weight: bold;
  font-size: 14px;
}
.titleLabel > span:hover {
  color: #308cd2;
  font-weight: bold;
}
.titleLabel > p:hover {
  color: #308cd2;
  font-weight: bold;
}

.el-icon-arrow-right {
  font-weight: bold;
}

.titleWord {
  width: 100%;
  height: 98%;
}

.WordText {
  display: flex;
  width: 100%;
  height: 95%;
}

.rightWord {
  width: 80%;
  padding: 20px 20px;
  font-size: 18px;
  font-weight: bold;
  color: #666666;
}

.leftWord {
  width: 20%;
  height: 75vh;
  margin-left: auto;
}

.SignBut {
  width: 100%;
  height: 45px;
  background-color: #ed661b;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  margin: 30px auto;
}

.TimeBut {
  width: 100%;
  height: 45px;
  background-color: #a6a6a6;
  text-align: center;
  line-height: 45px;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
  cursor: pointer;
  letter-spacing: 2px;
  position: relative;
  overflow: hidden;
  margin: 30px auto;
}
.SignBut:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  background-image: radial-gradient(circle, #ffffff 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform .3s, opacity .5s;
}

.SignBut:active:after {
  transform: scale(0, 0);
  opacity: .3;
  transition: 0s;
}

.step {
  height: 400px;
  margin: 20px auto;
}

.stepRound {
  width: 5px;
  height: 5px;
  border: 5px solid #10539B;
  border-radius: 50%;
}

.xianS {
  width: 0px;
  height: 60px;
  border: 1px dashed #e3e3e3;
  margin-left: 6px;
  margin-top: 2px;
}

.stepMessage {
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
}

.MessageStep {
  width: 90%;
  height: 100%;
}

.MessageStep > h1 {
  color: #4a74c4;
  font-size: 14px;
  font-weight: bold;
}

.MessageStep > p {
  font-size: 14px;
  color: #afafaf;
  margin-top: 8px;
  padding: 1px 0px;
}

.situation {
  /*height: 1320px;*/
  margin: 10px auto;
  padding: 20px 20px;
  font-size: 14px;
}

.tender {
  /*width: 100%;*/
  /*height: 80px;*/
  background-color: #f7f8f9;
  margin: 20px auto;
  /*display: flex;*/
  padding: 20px 20px;
}

.tender2 {
  display: flex;
  /*width: 100%;*/
  /*height: 80px;*/
  background-color: #f7f8f9;
  margin: 20px auto;
  /*display: flex;*/
  padding: 20px 20px 10px;
}
.tender2-item1 {
  flex: 3;
}

.tender2-item2 {
  flex: 7;
}

.tender2-text {
  margin-bottom: 10px;
}

.tender-title {
  margin-bottom: 14px;
}

.tender > span {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.tender > span:nth-child(2) {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 80px;
}

.tender > div {
  margin-bottom: 10px;
  /*line-height: 20px;*/
  /*font-size: 16px;*/
}

.tender > div:last-of-type {
  margin-bottom: 0;
}

/*.pdfExhibition{*/
/*  width: 100%;*/
/*  height: 690px;*/
/*  background-color: #7ac23c;*/
/*  margin: 20px auto;*/
/*}*/
/*.topText{*/
/*  width: 100%;*/
/*  height: 50px;*/
/*  !*background-color: #7ac23c;*!*/
/*  margin: 50px auto;*/
/*  text-align: left;*/
/*  font-size: 18px;*/
/*}*/

.right-tender {
  font-size: 16px;
  background-color: #f7f8f9;
  padding: 30px 50px 70px 60px;
}

.right-item {
  line-height: 60px;
}

.file-item {
  padding-left: 130px;
  margin-top: -60px;
}

.right-item-label {
  display: inline-block;
  width: 120px;
  text-align: right;
  font-weight: 600;
  color: #ec661b;
  /*color: #d2aa82;*/
  margin-right: 14px;
}

.a-style {
  /*color: #ed661b;*/
  font-weight: normal;
  /*color: #c2a73c;*/
  text-decoration: none;
}
</style>
