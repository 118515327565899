<template>
  <div class="all">
    <div class="topTitle">
      <div class="title">
        <div class="imgLogo"><img class="home" src="../assets/img/logo2.png">
          <p>环宇建筑科技股份有限公司</p>
        </div>
        <div class="tab_but">
          <router-link :to="{name:'Home'}" class="but_tab"> 网站首页</router-link>
          <router-link :to="{name:'Recruiting'}" class="but_tab_Recruiting"> 招标公告</router-link>
          <router-link :to="{name:'Winning'}" class="but_tab"> 中标公示</router-link>
          <router-link :to="{name:'Service'}" class="but_tab"> 企业服务</router-link>
          <router-link :to="{name:'Help'}" class="but_tab"> 帮助中心</router-link>
          <div v-if="EUJSESSIONID !== null" class="but_tab" @click="exitBut">退出登录</div>
        </div>
      </div>
      <!--    搜索表格-->
      <div class="tab_bg">
        <div v-if="show1">
          <!--        筛选框-->
          <div class="screening">
            <div style="display: flex; width: 60%; margin: 0 auto">
              <div class="search">
                <i class="el-icon-search"></i>
                <el-input v-model="search_input" class="input" @keyup.enter.native="searchI" placeholder="请输入关键字">
                </el-input>
              </div>
              <button class="ser-button" @click="searchI">搜 索</button>
            </div>
            <div class="conditions">
              <p>发布时间：</p>
              <div v-for="(item,index) in screening" :key="index" :class="{active:categoryIndex==item}" class="ScrBut"
                   @click="clickCategory(item)"><p>{{ item }}</p>
              </div>
              <div v-if="visible" style="padding-left: 20px;">
                <el-date-picker
                  v-model="moreTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="changeMore">
                </el-date-picker>
              </div>
            </div>
          </div>
          <!--        线条-->
          <div class="x"></div>
          <!--        列表数据-->
          <div class="listRe">
            <div v-if="ReTingList.length != 0">
              <!--          列表内容框-->
              <div v-for="(item,index) in ReTingList" :key="index" class="content" @click="details(item)">
                <!--            上面部分-->
                <div class="topContent">
                  <h1>{{ item.subject }}</h1>
                  <p>{{ item.createDate }}</p>
                </div>
                <!--            下面部分-->
                <div class="bottomContent">
                  <div class="notStyle common-bottom"><p>{{ item.company }}</p></div>
                  <div class="bottomContent_x common-bottom">名称: <p>{{ item.name }}</p></div>
                  <div class="bottomContent_x common-bottom">状态: <p>{{ item.status === 'pending' ? '进行中': '已截止' }}</p></div>
                  <div class="notStyle1 common-bottom">报名截止时间: <p>{{ item.signUpEndDate }}</p></div>
                </div>
              </div>
              <div class="pages">
                <el-pagination
                  :page-count="this.pages"
                  background
                  @current-change="handleCurrentChange"
                  layout="prev, pager, next">
                </el-pagination>
              </div>
            </div>
            <div v-else>
              <div class="hint">暂无数据</div>
            </div>
          </div>
        </div>
        <!--        详情-->
        <tenderDetails v-if="show2"></tenderDetails>
      </div>
    </div>
    <div class="cont"></div>
    <!--    底部-->
    <div class="bottom_d">
      <div class="logo_d">
        <img src="../assets/img/back.png">
        <p>
          地址：浙江省绍兴市凤林西路300号环宇大厦<br>
          电话：0575-85132445 85336908<br>
          传真：0575-85117404 85336900<br>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #FFFFFF">备案：浙ICP备11004969号-1</a>
        </p>
      </div>
      <div class="wx_logo">
        <img src="../assets/img/logo3.png">
        <p>
          微信公众号：hyjzkj
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { exitLogin, RecruitingAt } from '@/api/api'
import tenderDetails from '@/components/details/tender_details'
import axios from 'axios'

export default {
  name: 'Recruiting',
  components: {
    tenderDetails
  },
  data () {
    return {
      pages: 0,
      moreTime: '',
      visible: false,
      EUJSESSIONID: '',
      categoryIndex: '全部',
      screening: {
        all: '全部',
        today: '今天',
        days: '最近三天',
        SevenDay: '最近七天',
        AMonth: '最近1个月',
        TMonth: '最近3个月',
        more: '更多'
      },
      ReTingList: [{}],
      page: 1,
      total: 0,
      size: 5,
      record: 0,
      search_input: '',
      show1: true,
      show2: false,
      rid: this.$route.params.Rid // 首页跳转详情ID
    }
  },
  created () {
    this.getUrl()
    this.EUJSESSIONID = localStorage.getItem('EUJSESSIONID')
    this.ReMessage()
  },
  methods: {
    getUrl () {
      const id = this.$route.query.id
      if (id) {
        localStorage.setItem('Rid', id)
        this.show1 = false
        this.show2 = true
      } else if (this.rid) {
        console.log(this.rid)
        localStorage.setItem('Rid', this.rid)
        this.show1 = false
        this.show2 = true
      } else {
        this.show1 = true
        this.show2 = false
      }
    },
    searchI () {
      axios.defaults.headers.token = localStorage.getItem('token')
      RecruitingAt({
        method: 'tenderInfos',
        size: this.size,
        page: this.page,
        // tName: this.search_input,
        subject: this.search_input,
        status: 'pending',
        _type_: 'date',
        condition: 'createDate',
        value: []
      }, res => {
        this.ReTingList = res.data.data.data
        this.total = res.data.data.total
      })
    },
    home () {
      this.$router.replace('/')
    },
    // 退出登录
    exitBut () {
      exitLogin({
        method: 'logout'
      }, res => {
        this.$message.info('已注销用户！')
        this.$router.replace('/login/login')
        this.$cookies.remove('token', 'EUJSESSIONID=egCR0M2aKCuk46H9fucdWyPBsqeZTFGnU%2Fylt4meBeE%3D')
        localStorage.removeItem('EUJSESSIONID')
      })
    },
    clickCategory (item) { // 这里我们传入一个当前值
      var neweTime = new Date()
      if (neweTime.getTime() - this.record > this.time) {
        this.$emit('click')
      }
      this.record = (new Date().getTime())
      this.categoryIndex = item
      if (this.categoryIndex === '全部') {
        axios.defaults.headers.token = localStorage.getItem('token')
        RecruitingAt({
          method: 'tenderInfos',
          size: this.size,
          page: this.page,
          // tName: '',
          status: 'pending',
          _type_: 'date',
          condition: 'createDate',
          value: []
        }, res => {
          this.ReTingList = res.data.data.data
          this.total = res.data.data.total
        })
      } else if (this.categoryIndex === '今天') {
        axios.defaults.headers.token = localStorage.getItem('token')
        RecruitingAt({
          method: 'tenderInfos',
          size: this.size,
          page: this.page,
          // tName: '',
          status: 'pending',
          _type_: 'date',
          condition: 'createDate',
          value: [
            this.dateFormat(Date()),
            this.dateFormat(Date())
          ]
        }, res => {
          this.ReTingList = res.data.data.data
          this.total = res.data.data.total
        })
      } else if (this.categoryIndex === '最近三天') {
        axios.defaults.headers.token = localStorage.getItem('token')
        RecruitingAt({
          method: 'tenderInfos',
          size: this.size,
          page: this.page,
          // tName: '',
          status: 'pending',
          _type_: 'date',
          condition: 'createDate',
          value: [
            this.getDay(-3),
            this.dateFormat(Date())
          ]
        }, res => {
          this.ReTingList = res.data.data.data
          this.total = res.data.data.total
        })
      } else if (this.categoryIndex === '最近七天') {
        axios.defaults.headers.token = localStorage.getItem('token')
        RecruitingAt({
          method: 'tenderInfos',
          size: this.size,
          page: this.page,
          // tName: '',
          status: 'pending',
          _type_: 'date',
          condition: 'createDate',
          value: [
            this.getDay(-7),
            this.dateFormat(Date())
          ]
        }, res => {
          this.ReTingList = res.data.data.data
          this.total = res.data.data.total
        })
      } else if (this.categoryIndex === '最近1个月') {
        axios.defaults.headers.token = localStorage.getItem('token')
        RecruitingAt({
          method: 'tenderInfos',
          size: this.size,
          page: this.page,
          // tName: '',
          status: 'pending',
          _type_: 'date',
          condition: 'createDate',
          value: [
            this.oneMonth(Date()),
            this.dateFormat(Date())
          ]
        }, res => {
          this.ReTingList = res.data.data.data
          this.total = res.data.data.total
        })
      } else if (this.categoryIndex === '最近3个月') {
        axios.defaults.headers.token = localStorage.getItem('token')
        RecruitingAt({
          method: 'tenderInfos',
          size: this.size,
          page: this.page,
          // tName: '',
          status: 'pending',
          _type_: 'date',
          condition: 'createDate',
          value: [
            this.getDay(-90),
            this.dateFormat(Date())
          ]
        }, res => {
          this.ReTingList = res.data.data.data
          this.total = res.data.data.total
        })
      }

      if (this.categoryIndex === '更多') {
        this.visible = true
      } else {
        this.visible = false
      }
    },

    //  查询全部数据
    ReMessage () {
      axios.defaults.headers.token = localStorage.getItem('token')
      RecruitingAt({
        method: 'tenderInfos',
        size: this.size,
        page: this.page,
        // tName: '',
        // status: 'pending',
        _type_: 'date',
        condition: 'createDate',
        value: []
      }, res => {
        this.ReTingList = res.data.data.data
        this.pages = res.data.data.pages
      })
    },
    async handleCurrentChange (page) {
      axios.defaults.headers.token = localStorage.getItem('token')
      await RecruitingAt({
        method: 'tenderInfos',
        size: this.size,
        page: page,
        // tName: '',
        // status: 'pending',
        _type_: 'date',
        condition: 'createDate',
        value: []
      }, res => {
        this.ReTingList = res.data.data.data
        this.pages = res.data.data.pages
      })
    },
    dateFormat (time) {
      // 将13位时间戳转换成时间格式  输出为2018-10-09
      const date = new Date(time)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      var myDate = ''
      myDate = year + '-' + month + '-' + day
      return myDate
    },
    oneMonth (s) {
      const currDate = new Date().toLocaleDateString()// 2020/11/6
      const arrs = currDate.split('/')
      const yy = parseInt(arrs[0])
      const mm = parseInt(arrs[1])
      const dd = parseInt(arrs[2])
      const pdStr = new Date(yy, mm - 4, dd).toLocaleDateString().split('/').join('-')
      return pdStr // 2020-8-6；可针对需要的格式进行优化。
    },
    // 根据传参获取日期
    getDay (day) {
      var today = new Date()
      var targe = today.getTime() + 1000 * 60 * 60 * 24 * day
      today.setTime(targe) // 注意，这行是关键代码

      var tYear = today.getFullYear()
      var tMonth = today.getMonth()
      var tDate = today.getDate()
      tMonth = this.doHandleMonth(tMonth + 1)
      tDate = this.doHandleMonth(tDate)
      return tYear + '-' + tMonth + '-' + tDate
    },
    doHandleMonth (month) {
      var m = month
      if (month.toString().length === 1) {
        m = '0' + month
      }
      return m
    },
    details (item) {
      localStorage.setItem('Rid', item.id)
      this.show1 = false
      this.show2 = true
    },
    changeMore (e) {
      const start = new Date(e[0])
      const end = new Date(e[1])
      const arr = []
      arr.push(start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate())
      arr.push(end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate())
      axios.defaults.headers.token = localStorage.getItem('token')
      RecruitingAt({
        method: 'tenderInfos',
        size: this.size,
        page: this.page,
        // tName: '',
        status: 'pending',
        _type_: 'date',
        condition: 'createDate',
        value: arr
      }, res => {
        this.ReTingList = res.data.data.data
        this.total = res.data.data.total
      })
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.home {
  cursor: pointer;
}

/*全部*/
.all {
  position: relative;
  width: 100%;
  /*height: 2150px;*/
  margin: 0 auto;
  background-color: #f7f8f9;
}

.tab_bg {
  position: relative;
  width: 1200px;
  /*height: 1450px;*/
  background-color: #ffffff;
  margin: 150px auto 0;
  padding: 20px 20px 0;
}
.imgLogo >p{
  color: #FFFFFF;
  line-height: 10px;
  padding-left: 67px;
  letter-spacing: 1px;
  font-size: 23.2px;
  cursor: pointer;
  font-weight: bold;
}
/*分页*/
/*.pages {*/
/*  position: absolute;*/
/*  height: 50px;*/
/*  bottom: 0;*/
/*  width: 100%;*/
/*  !*background-color: #EA5D5C;*!*/
/*  display: flex;*/
/*  justify-content: center;*/
/*  align-items: center;*/
/*}*/

.pages {
  position: absolute;
  bottom: 0;
  height: 50px;
  width: 100%;
  /*background-color: #EA5D5C;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

/*底部*/
.bottom_d {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 360px;
  background-color: #191919;
  text-align: left;
  margin: 0px auto;
}

/*顶部title*/
.topTitle {
  width: 100%;
  height: 360px;
  background-color: #ffffff;
  background-image: url("../assets/img/bannerZM.png");
  background-size: 100% 100%;
}

.title {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 90px;
  background-color: black;
  opacity: 0.68;
}

.imgLogo > img {
  width: 359px;
  height: 54px;
  padding-top: 10px;
}

.tab_but {
  display: flex;
  width: 600px;
  height: 90px;
  color: #ffffff;
  padding-top: 20px;
  text-align: center;
}

.but_tab {
  font-size: 16px;
  width: 220px;
  height: 40px;
  line-height: 40px;
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.but_tab_Recruiting {
  font-size: 16px;
  width: 220px;
  height: 40px;
  color: #10539B;
  background-color: white;
  line-height: 40px;
  cursor: pointer;
  text-decoration: none;
}

.but_tab:hover {
  width: 220px;
  height: 40px;
  color: #10539B;
  background-color: white;
  cursor: pointer;
}

/*中间占位*/
.cont {
  position: relative;
  z-index: -100;
  width: 100%;
  /*height: 800px;*/
  min-height: 1300px;
}

.logo_d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 250px;
  margin-top: 90px;
  color: white;
  line-height: 30px;
}

.logo_d > img {
  width: 300px;
  height: 90px;
  margin-left: -28px;
  margin-top: 38px;
}

.wx_logo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 190px;
  /*background-color: #554ace;*/
  margin-top: 90px;
  color: white;
  line-height: 30px;
}

.wx_logo > img {
  width: 142px;
  height: 142px;
}

.screening {
  width: 100%;
  height: 180px;
  /*background: #7ac23c;*/
}

.search {
  width: 100%;
  /*margin: 0 auto;*/
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid #69a0ec;
  padding: 0 20px;
}

.input >>> .el-input__inner {
  border: none;
}

.conditions {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  /*background-color: #EA5D5C;*/
  border-bottom: 1px solid #f6f0f0;
  margin: 40px auto;
}

.conditions > p {
  width: 80px;
  font-size: 14px;
  font-weight: bold;
  color: #666666;
  text-align: right;
}

.ScrBut {
  width: 78px;
  height: 25px;
  margin-left: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666666;
  border-radius: 2px;
}

.ScrBut:hover {
  width: 78px;
  height: 25px;
  background: #fdf3ec;
  margin-left: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff9127;
  border: 1px solid #ff9127;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
}

.active {
  width: 78px;
  height: 25px;
  background: #fdf3ec;
  margin-left: 20px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff9127;
  border: 1px solid #ff9127;
  border-radius: 2px;
  box-sizing: border-box;
}

.x {
  width: 100%;
  height: 4px;
  background-color: #1789e5;
}

.listRe {
  width: 100%;
  height: 680px;
  margin: 20px auto;
}

.content {
  width: 95%;
  height: 108px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding: 0 20px;
}

.content:nth-child(2n) {
  background-color: #f7f8f9;
  width: 95%;
  height: 108px;
  margin: 10px auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.topContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50%;
}

.topContent > h1 {
  font-size: 20px;
  color: #000001;
}

.topContent > h1:hover {
  color: #1789e5;
  cursor: pointer;
}

.topContent > p {
  font-size: 14px;
  color: #999999;
  font-weight: bold;
}

.bottomContent {
  display: flex;
  /*align-items: center;*/
  justify-content: left;
  width: 100%;
  height: 50%;
}

.notStyle {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  justify-content: center;
}

.notStyle > p {
  font-size: 14px;
  color: #666666 ;
  margin-left: 10px;
}

.notStyle1 {
  /*width: 250px;*/
  /*height: 40px;*/
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-size: 14px;
  /*margin-left: 30px;*/
}
.notStyle1>p{
  color: #666666;
  margin-left: 10px;
}
.bottomContent_x {
  height: 40px;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}

.common-bottom {
  padding: 4px 20px;
}

.common-bottom:nth-child(1) {
  padding-left: 0;
}

.bottomContent_x > p {
  color: #666666;
  margin-left: 10px;
}

.hint {
  text-align: center;
  margin-top: 90px;
}

.ser-button {
  width: 120px;
  height: 42px;
  color: #FFFFFF;
  background-color: #1789e5;
  margin-left: 10px;
  border-radius: 30px;
  border: 0px;
  cursor: pointer;
  font-size: 17px;
  font-family: "Microsoft YaHei",微软雅黑;
}
</style>
